import React from 'react'
import { H4 } from '~/styled/components/typography'
import { ProductTitleWrapper, ProductPriceRow } from './styles'
import { Tag } from '~/componentsV2/tag'
import { Box, Flex, Link } from 'theme-ui'
import { useCustomerContext } from '~/context/customer-context'
import { all } from 'ramda'

// const memorialDayRugs = ['CONTEMPORARY RUGS', 'TRADITIONAL RUGS', 'MOROCCAN RUGS']
// const isMemorialDayRug = memorialDayRugs.includes(product?.productType)
// // discount message for trade
// const messages = {
//   trade: '25% OFF TRADE RUG SALE | FINAL SALE',
//   memorialDayRug: '20% OFF RUG SALE | FINAL SALE',
// }

// const showPriceWithoutDiscount = productPrice.isTradeAccountLabelVisible || isMemorialDayRug
// const showTradePrice = productPrice.isTradeAccountLabelVisible && !isMemorialDayRug

interface Props {
  currentPrice?: any
  inStock?: boolean
  isBundle?: boolean
}
const ProductPriceContainer = ({ currentPrice, inStock, isBundle }: Props) => {
  const { auth } = useCustomerContext()

  const PriceTagInfo = ({ children, link }: { children: React.ReactNode; link?: string }) => {
    if (link) {
      return (
        <a href={link}>
          <Tag
            bgColor="transparent"
            style={{
              cursor: 'pointer',
              fontWeight: 400,
              textDecoration: 'underline',
              padding: '5px 0',
              textUnderlineOffset: '3px',
            }}
          >
            {children}
          </Tag>
        </a>
      )
    }

    return (
      <Tag bgColor="transparent" style={{ padding: '5px 0', minWidth: 'auto' }}>
        {children}
      </Tag>
    )
  }

  const { compareAtPrice, price, discountMessage } = isBundle
    ? {
        compareAtPrice: currentPrice?.tradePriceFormatted
          ? currentPrice?.priceFormatted
          : currentPrice?.compareAtPriceFormatted,
        price: currentPrice?.tradePriceFormatted
          ? currentPrice?.tradePriceFormatted
          : currentPrice?.priceFormatted,
        discountMessage: currentPrice?.isTrade ? 'TRADE PRICE' : null,
      }
    : {
        compareAtPrice: currentPrice?.compareAtPriceFormatted,
        price: currentPrice?.priceFormatted,
        discountMessage: currentPrice?.isSale ? null : currentPrice?.defaultDiscountMessage,
      }

  return currentPrice.visible ? (
    <ProductTitleWrapper>
      <ProductPriceRow>
        <Flex sx={{ gap: '16px', rowGap: 0, justifyContent: 'start' }}>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              letterSpacing: '0.5px',
              color: '#2b2828',
              margin: 0,
            }}
          >
            {price}
          </p>

          {compareAtPrice ? (
            <p
              style={{
                fontSize: '18px',
                letterSpacing: '0.5px',
                color: '#585858',
                margin: 0,
              }}
            >
              <s>{compareAtPrice}</s>
            </p>
          ) : null}
        </Flex>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          {currentPrice.isSale ? (
            <PriceTagInfo link="/pages/faqs#faq-what-are-the-terms-and-conditions-of-your-offers-and-promotions">
              FINAL SALE
            </PriceTagInfo>
          ) : null}
          {discountMessage ? <PriceTagInfo>{discountMessage}</PriceTagInfo> : null}

          {!currentPrice.currentlyNotInStock && inStock ? (
            <PriceTagInfo>IN STOCK</PriceTagInfo>
          ) : null}

          {!auth?.accessToken && !currentPrice.compareAtPrice && (
            <PriceTagInfo link="/sign-in">TRADE PRICE AVAILABLE</PriceTagInfo>
          )}
        </Box>
      </ProductPriceRow>
      {/* {isMemorialDayRug && !productPrice.isSale && (
            <Tag bgColor="transparent">
              {productPrice.isTradeAccountLabelVisible ? messages.trade : messages.memorialDayRug}
            </Tag>
          )} */}
    </ProductTitleWrapper>
  ) : null
}

export default ProductPriceContainer
